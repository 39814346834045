const Line = () => {
  return {
    option: {
      backgroundColor: "",
      color: ["#7CFFB2", "skyblue"],
      xAxis: {
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      grid: {
        x: 30,
        y: 20,
        x2: 0,
        y2: 30,
        bottom: "1%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        // formatter: "{a} <br/>{b} : {c}",
      },
      legend: {
        data: ["设定温度", "运行温度"],
        textStyle: {
          color: "#fff",
        },
      },
      yAxis: {
        type: "value",
        max: 1400,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      series: [
        {
          name: "设定温度",
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: "line",
          smooth: true,
        },
        {
          name: "运行温度",
          data: [8, 632, 701, 634, 990, 230, 920],
          type: "line",
          smooth: true,
        },
      ],
    },
  };
};

const Yield = () => {
  return {
    option: {
      backgroundColor: "transparent",
      title: {
        text: "今日产量",
        textStyle: {
          color: "#fff",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        textStyle: {
          color: "#fff",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      yAxis: {
        type: "category",
        data: ["工件"],

        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      series: [
        {
          name: "上件",
          type: "bar",

          data: [18203],
        },
        {
          name: "下件",
          type: "bar",
          data: [19325],
        },
      ],
    },
  };
};

const Motor = () => {
  return {
    option: {
      backgroundColor: "",
      title: {
        text: "抛丸器电机频率 (HZ)",
        textStyle: {
          color: "#fff",
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",

        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      yAxis: {
        type: "value",
        // max: 100,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      series: [
        {
          data: [120, 200, 150, 80, 70, 110, 130],
          type: "bar",

          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
        },
      ],
    },
  };
};
export { Line, Yield, Motor };
