const overallData = {
  code: 0,
  data: {
    pageTabs: [
      {
        id: "1000011",
        tabType: 1,
        pageId: "100001",
        tabName: "全局",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000111",
              tabId: "1000011",
              rankOrder: 1,
              groupTitle: "全局基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000011100",
                dataKey: "R4-001",
                title: "系统状态",
                extJson: null,
                extJsonSuper: null,
                value: "运行中",
                dataType: "枚举",
                unit: "",
                rankOrder: 0,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R4-000",
              },
              {
                metaDataItemId: "1000011101",
                dataKey: null,
                title: "工件类型",
                extJson: null,
                extJsonSuper: null,
                value: "工件1批4件",
                dataType: null,
                unit: null,
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: null,
              },
              {
                metaDataItemId: "1000011102",
                dataKey: null,
                title: "产线模式",
                extJson: null,
                extJsonSuper: null,
                value: "联调加热炉-r2-淬火",
                dataType: null,
                unit: null,
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: null,
              },
              {
                metaDataItemId: "1000011104",
                dataKey: null,
                title: "当前操作员",
                extJson: null,
                extJsonSuper: null,
                value: "苏强,翁文超,曌曌",
                dataType: null,
                unit: null,
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: null,
              },
              {
                metaDataItemId: "1000011105",
                dataKey: null,
                title: "当前班次",
                extJson: null,
                extJsonSuper: null,
                value: "2023-02-21  早班",
                dataType: null,
                unit: null,
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: null,
              },
            ],
          },
        ],
        chartValue: null,
      },
      {
        id: "1000012",
        tabType: 1,
        pageId: "100001",
        tabName: "加热炉",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000121",
              tabId: "1000012",
              rankOrder: 1,
              groupTitle: "加热炉基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000011201",
                dataKey: "J-001",
                title: "加热炉状态",
                extJson: null,
                extJsonSuper: null,
                value: "步进中》》",
                dataType: "枚举",
                unit: "",
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011202",
                dataKey: "J-003",
                title: "网带步进间隔",
                extJson: null,
                extJsonSuper: null,
                value: "15",
                dataType: "",
                unit: "秒",
                rankOrder: 2,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011203",
                dataKey: "J-004",
                title: "网带步进速度",
                extJson: null,
                extJsonSuper: null,
                value: "0.22",
                dataType: "",
                unit: "m/s",
                rankOrder: 3,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011204",
                dataKey: "J-005",
                title: "入口实时温度",
                extJson: null,
                extJsonSuper: null,
                value: "1",
                dataType: "温度",
                unit: "°C",
                rankOrder: 4,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011205",
                dataKey: "J-006",
                title: "出口实时温度",
                extJson: null,
                extJsonSuper: null,
                value: "960",
                dataType: "温度",
                unit: "°C",
                rankOrder: 5,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011206",
                dataKey: "J-007",
                title: "网带偏差均值",
                extJson: null,
                extJsonSuper: null,
                value: "5",
                dataType: "",
                unit: "mm",
                rankOrder: 6,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
            ],
          },
          {
            groupInfo: {
              id: "10000122",
              tabId: "1000012",
              rankOrder: 1,
              groupTitle: "加热炉温度图",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000012201",
                dataKey: "J-008",
                title: "T1",
                extJson: null,
                extJsonSuper: null,
                value: "800",
                dataType: "温度",
                unit: "",
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012202",
                dataKey: "J-012",
                title: "T2",
                extJson: null,
                extJsonSuper: null,
                value: "900",
                dataType: "温度",
                unit: "",
                rankOrder: 2,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012203",
                dataKey: "J-016",
                title: "T3",
                extJson: null,
                extJsonSuper: null,
                value: "1000",
                dataType: "温度",
                unit: "",
                rankOrder: 3,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012204",
                dataKey: "J-020",
                title: "T4",
                extJson: null,
                extJsonSuper: null,
                value: "1000",
                dataType: "温度",
                unit: "",
                rankOrder: 4,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012205",
                dataKey: "J-024",
                title: "T5",
                extJson: null,
                extJsonSuper: null,
                value: "1000",
                dataType: "温度",
                unit: "",
                rankOrder: 5,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012206",
                dataKey: "J-029",
                title: "T6",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "",
                unit: "",
                rankOrder: 6,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012207",
                dataKey: "J-032",
                title: "T7",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "温度",
                unit: "",
                rankOrder: 7,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012208",
                dataKey: "J-036",
                title: "T8",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "温度",
                unit: "",
                rankOrder: 8,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012209",
                dataKey: "J-040",
                title: "T9",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "温度",
                unit: "",
                rankOrder: 9,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012210",
                dataKey: "J-044",
                title: "T10",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 10,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012211",
                dataKey: "J-048",
                title: "T11",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 11,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012212",
                dataKey: "J-052",
                title: "T12",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 12,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012213",
                dataKey: "J-056",
                title: "T13",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 13,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012214",
                dataKey: "J-060",
                title: "T14",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "温度",
                unit: "",
                rankOrder: 14,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012215",
                dataKey: "J-064",
                title: "T15",
                extJson: null,
                extJsonSuper: null,
                value: "1000",
                dataType: "温度",
                unit: "",
                rankOrder: 15,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012216",
                dataKey: "J-068",
                title: "T16",
                extJson: null,
                extJsonSuper: null,
                value: "1000",
                dataType: "温度",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
            ],
          },
          {
            groupInfo: {
              id: "10000123",
              tabId: "1000012",
              rankOrder: 1,
              groupTitle: "加热炉实时温度",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000012317",
                dataKey: "J-079",
                title: "T1运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "780",
                dataType: "温度",
                unit: "",
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012318",
                dataKey: "J-080",
                title: "T2运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "780",
                dataType: "温度",
                unit: "",
                rankOrder: 2,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012319",
                dataKey: "J-081",
                title: "T3运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "970",
                dataType: "温度",
                unit: "",
                rankOrder: 3,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012320",
                dataKey: "J-082",
                title: "T4运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "990",
                dataType: "温度",
                unit: "",
                rankOrder: 4,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012321",
                dataKey: "J-083",
                title: "T5运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1050",
                dataType: "温度",
                unit: "",
                rankOrder: 5,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012322",
                dataKey: "J-084",
                title: "T6运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1050",
                dataType: "温度",
                unit: "",
                rankOrder: 6,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012323",
                dataKey: "J-085",
                title: "T7运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1120",
                dataType: "温度",
                unit: "",
                rankOrder: 7,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012324",
                dataKey: "J-086",
                title: "T8运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1120",
                dataType: "温度",
                unit: "",
                rankOrder: 8,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012325",
                dataKey: "J-087",
                title: "T9运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1180",
                dataType: "温度",
                unit: "",
                rankOrder: 9,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012326",
                dataKey: "J-088",
                title: "T10运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "温度",
                unit: "",
                rankOrder: 10,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012327",
                dataKey: "J-089",
                title: "T11运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1080",
                dataType: "温度",
                unit: "",
                rankOrder: 11,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012328",
                dataKey: "J-090",
                title: "T12运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1020",
                dataType: "温度",
                unit: "",
                rankOrder: 12,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012329",
                dataKey: "J-091",
                title: "T13运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1010",
                dataType: "温度",
                unit: "",
                rankOrder: 13,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012330",
                dataKey: "J-092",
                title: "T14运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "1000",
                dataType: "温度",
                unit: "",
                rankOrder: 14,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012331",
                dataKey: "J-093",
                title: "T15运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "990",
                dataType: "温度",
                unit: "",
                rankOrder: 15,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012332",
                dataKey: "J-094",
                title: "T16运行温度",
                extJson: null,
                extJsonSuper: null,
                value: "960",
                dataType: "温度",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
            ],
          },
        ],
        chartValue: null,
      },
      {
        id: "1000013",
        tabType: 1,
        pageId: "100001",
        tabName: "淬火",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000131",
              tabId: "1000013",
              rankOrder: 1,
              groupTitle: "淬火基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000013101",
                dataKey: "C-001",
                title: "淬火状态",
                extJson: null,
                extJsonSuper: null,
                value: "运行中",
                dataType: "枚举",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013102",
                dataKey: "C-005",
                title: "淬火时间设定",
                extJson: null,
                extJsonSuper: null,
                value: "5",
                dataType: "",
                unit: "秒",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013103",
                dataKey: "C-006",
                title: "水泵压力",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "Mpa",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013104",
                dataKey: "C-007",
                title: "流量监视",
                extJson: null,
                extJsonSuper: null,
                value: "-0.029",
                dataType: "",
                unit: "L/S",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013105",
                dataKey: "C-008",
                title: "溢流阀压力",
                extJson: null,
                extJsonSuper: null,
                value: "2",
                dataType: "",
                unit: "Mpa",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
            ],
          },
          {
            groupInfo: {
              id: "10000132",
              tabId: "1000013",
              rankOrder: 1,
              groupTitle: "淬火其他状态",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000013206",
                dataKey: "C-009",
                title: "水箱水位状态",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013207",
                dataKey: "C-010",
                title: "设备水位状态",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013208",
                dataKey: "C-011",
                title: "设备底座水位",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
            ],
          },
        ],
        chartValue: null,
      },
      {
        id: "1000014",
        tabType: 1,
        pageId: "100001",
        tabName: "抛丸喷油",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000141",
              tabId: "1000014",
              rankOrder: 1,
              groupTitle: "抛丸喷油基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000014104",
                dataKey: "PWPY-001",
                title: "抛丸喷油状态",
                extJson: null,
                extJsonSuper: null,
                value: "运行中",
                dataType: "枚举",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014105",
                dataKey: "PWPY-002",
                title: "抛丸运行状态",
                extJson: null,
                extJsonSuper: null,
                value: "运行中",
                dataType: "枚举",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014106",
                dataKey: "PWPY-003",
                title: "喷油运行状态",
                extJson: null,
                extJsonSuper: null,
                value: "运行中",
                dataType: "枚举",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014107",
                dataKey: "PWPY-004",
                title: "网带设定速度",
                extJson: null,
                extJsonSuper: null,
                value: "0.4",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014108",
                dataKey: "PWPY-005",
                title: "网带运行状态",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014109",
                dataKey: "PWPY-006",
                title: "卸料阀状态",
                extJson: null,
                extJsonSuper: null,
                value: "1",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014110",
                dataKey: "PWPY-015",
                title: "除尘器水位",
                extJson: null,
                extJsonSuper: null,
                value: "20",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014111",
                dataKey: "PWPY-016",
                title: "补油桶液位",
                extJson: null,
                extJsonSuper: null,
                value: "90",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014112",
                dataKey: "PWPY-017",
                title: "喷油桶1液位",
                extJson: null,
                extJsonSuper: null,
                value: "90",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014113",
                dataKey: "PWPY-018",
                title: "喷油桶2液位",
                extJson: null,
                extJsonSuper: null,
                value: "50",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
            ],
          },
          {
            groupInfo: {
              id: "10000142",
              tabId: "1000014",
              rankOrder: 1,
              groupTitle: "抛丸喷油电机频率",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000014201",
                dataKey: "PWPY-007",
                title: "抛头1",
                extJson: null,
                extJsonSuper: null,
                value: "60",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014202",
                dataKey: "PWPY-008",
                title: "抛头2",
                extJson: null,
                extJsonSuper: null,
                value: "60",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014203",
                dataKey: "PWPY-009",
                title: "抛头3",
                extJson: null,
                extJsonSuper: null,
                value: "58",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014204",
                dataKey: "PWPY-010",
                title: "抛头4",
                extJson: null,
                extJsonSuper: null,
                value: "60",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014205",
                dataKey: "PWPY-011",
                title: "抛头5",
                extJson: null,
                extJsonSuper: null,
                value: "59",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014206",
                dataKey: "PWPY-012",
                title: "抛头6",
                extJson: null,
                extJsonSuper: null,
                value: "56",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014207",
                dataKey: "PWPY-013",
                title: "抛头7",
                extJson: null,
                extJsonSuper: null,
                value: "60",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000014208",
                dataKey: "PWPY-014",
                title: "抛头8",
                extJson: null,
                extJsonSuper: null,
                value: "60",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
            ],
          },
          {
            groupInfo: {
              id: "10000143",
              tabId: "1000014",
              rankOrder: 1,
              groupTitle: "抛丸喷油油箱",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [],
          },
        ],
        chartValue: null,
      },
      {
        id: "1000015",
        tabType: 1,
        pageId: "100001",
        tabName: "产量",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000151",
              tabId: "1000015",
              rankOrder: 1,
              groupTitle: "产量基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "100001500",
                dataKey: "JD-005",
                title: "上件量",
                extJson: null,
                extJsonSuper: null,
                value: "20000",
                dataType: "",
                unit: "",
                rankOrder: 0,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "JD-004",
              },
              {
                metaDataItemId: "100001501",
                dataKey: "JD-006",
                title: "下件量",
                extJson: null,
                extJsonSuper: null,
                value: "19600",
                dataType: "",
                unit: "",
                rankOrder: 0,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "JD-004",
              },
            ],
          },
        ],
        chartValue: null,
      },
      {
        id: "1000016",
        tabType: 1,
        pageId: "100001",
        tabName: "异常",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000161",
              tabId: "1000016",
              rankOrder: 1,
              groupTitle: "异常基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000016101",
                dataKey: null,
                title: "异常信息",
                extJson: null,
                extJsonSuper: null,
                value: null,
                dataType: null,
                unit: null,
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: null,
              },
            ],
          },
        ],
        chartValue: null,
      },
      {
        id: "1000017",
        tabType: 1,
        pageId: "100001",
        tabName: "状态流程",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000171",
              tabId: "1000017",
              rankOrder: 1,
              groupTitle: "状态流程基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000017101",
                dataKey: "R1-001",
                title: "R1",
                extJson: null,
                extJsonSuper: null,
                value: "取件中",
                dataType: "枚举",
                unit: "",
                rankOrder: 0,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R1-000",
              },
              {
                metaDataItemId: "1000017102",
                dataKey: "R1-004",
                title: "R1工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "4",
                dataType: "",
                unit: "",
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R1-000",
              },
              {
                metaDataItemId: "1000017103",
                dataKey: "J-001",
                title: "加热炉",
                extJson: null,
                extJsonSuper: null,
                value: "步进中",
                dataType: "枚举",
                unit: "",
                rankOrder: 2,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017104",
                dataKey: "J-002",
                title: "加热炉超时批次号",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 3,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017105",
                dataKey: "J-011",
                title: "T1工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 4,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017106",
                dataKey: "J-015",
                title: "T2工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "4",
                dataType: "",
                unit: "",
                rankOrder: 5,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017107",
                dataKey: "J-019",
                title: "T3工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 6,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017108",
                dataKey: "J-023",
                title: "T4工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 7,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017109",
                dataKey: "J-027",
                title: "T5工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 8,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017110",
                dataKey: "J-031",
                title: "T6工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "4",
                dataType: "",
                unit: "",
                rankOrder: 9,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017111",
                dataKey: "J-035",
                title: "T7工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 10,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017112",
                dataKey: "J-039",
                title: "T8工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 11,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017113",
                dataKey: "J-043",
                title: "T9工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 12,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017114",
                dataKey: "J-047",
                title: "T10工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "4",
                dataType: "",
                unit: "",
                rankOrder: 13,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017115",
                dataKey: "J-051",
                title: "T11工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 14,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017116",
                dataKey: "J-055",
                title: "T12工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 15,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017117",
                dataKey: "J-059",
                title: "T13工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017118",
                dataKey: "J-063",
                title: "T14工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "4",
                dataType: "",
                unit: "",
                rankOrder: 17,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017119",
                dataKey: "J-067",
                title: "T15工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 18,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017120",
                dataKey: "J-071",
                title: "T16工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 19,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017121",
                dataKey: "J-075",
                title: "T17工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 20,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017122",
                dataKey: "J-079",
                title: "T18工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "4",
                dataType: "",
                unit: "",
                rankOrder: 21,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000017123",
                dataKey: "R2-001",
                title: "R2",
                extJson: null,
                extJsonSuper: null,
                value: "空闲中",
                dataType: "枚举",
                unit: "",
                rankOrder: 22,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R2-000",
              },
              {
                metaDataItemId: "1000017124",
                dataKey: "R2-004",
                title: "R2工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 23,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R2-000",
              },
              {
                metaDataItemId: "1000017125",
                dataKey: "C-001",
                title: "淬火",
                extJson: null,
                extJsonSuper: null,
                value: "淬火中",
                dataType: "枚举",
                unit: "",
                rankOrder: 24,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000017126",
                dataKey: "C-004",
                title: "淬火工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "4",
                dataType: "",
                unit: "",
                rankOrder: 25,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000017127",
                dataKey: "R3-001",
                title: "R3",
                extJson: null,
                extJsonSuper: null,
                value: "空闲中",
                dataType: "枚举",
                unit: "",
                rankOrder: 26,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R3-000",
              },
              {
                metaDataItemId: "1000017128",
                dataKey: "R3-004",
                title: "R3工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 27,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R3-000",
              },
              {
                metaDataItemId: "1000017129",
                dataKey: "PWPY-001",
                title: "抛丸喷油",
                extJson: null,
                extJsonSuper: null,
                value: "运行中",
                dataType: "枚举",
                unit: "",
                rankOrder: 28,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "PWPY-000",
              },
              {
                metaDataItemId: "1000017131",
                dataKey: "CD-001",
                title: "粗定位",
                extJson: null,
                extJsonSuper: null,
                value: "工件到达",
                dataType: "枚举",
                unit: "",
                rankOrder: 29,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "CD-000",
              },
              {
                metaDataItemId: "1000017132",
                dataKey: "CD-004",
                title: "粗定位工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "2",
                dataType: "",
                unit: "",
                rankOrder: 30,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "CD-000",
              },
              {
                metaDataItemId: "1000017133",
                dataKey: "R4-001",
                title: "R4",
                extJson: null,
                extJsonSuper: null,
                value: "取件中",
                dataType: "枚举",
                unit: "",
                rankOrder: 31,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R4-000",
              },
              {
                metaDataItemId: "1000017134",
                dataKey: "R4-004",
                title: "R4工件数量",
                extJson: null,
                extJsonSuper: null,
                value: "2",
                dataType: "",
                unit: "",
                rankOrder: 32,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "R4-000",
              },
            ],
          },
        ],
        chartValue: null,
      },
    ],
  },
  msg: "操作成功",
};

// 工艺
const gyData = {
  code: 0,
  data: {
    pageTabs: [
      {
        id: "1000031",
        tabType: 1,
        pageId: "100003",
        tabName: "淬火",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000131",
              tabId: "1000013",
              rankOrder: 1,
              groupTitle: "淬火基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000013101",
                dataKey: "C-001",
                title: "淬火状态",
                extJson: null,
                extJsonSuper: null,
                value: "停止",
                dataType: "枚举",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013102",
                dataKey: "C-005",
                title: "淬火时间设定",
                extJson: null,
                extJsonSuper: null,
                value: "5",
                dataType: "",
                unit: "秒",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013103",
                dataKey: "C-006",
                title: "水泵压力",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "Mpa",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013104",
                dataKey: "C-007",
                title: "流量监视",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "L/S",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013105",
                dataKey: "C-008",
                title: "溢流阀压力",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "Mpa",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
            ],
          },
          {
            groupInfo: {
              id: "10000132",
              tabId: "1000013",
              rankOrder: 1,
              groupTitle: "淬火其他状态",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000013206",
                dataKey: "C-009",
                title: "水箱水位状态",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013207",
                dataKey: "C-010",
                title: "设备水位状态",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
              {
                metaDataItemId: "1000013208",
                dataKey: "C-011",
                title: "设备底座水位",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "C-000",
              },
            ],
          },
        ],
        chartValue: null,
      },
      {
        id: "1000032",
        tabType: 1,
        pageId: "100003",
        tabName: "加热炉",
        pagePosition: "LEFT",
        pagePositionOrder: 0,
        path: null,
        chartWidth: 1,
        style: 0,
        columnType: 0,
        columnNumber: 0,
        subTabs: [],
        dataValues: [
          {
            groupInfo: {
              id: "10000121",
              tabId: "1000012",
              rankOrder: 1,
              groupTitle: "加热炉基础",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000011201",
                dataKey: "J-001",
                title: "加热炉状态",
                extJson: null,
                extJsonSuper: null,
                value: "停止",
                dataType: "枚举",
                unit: "",
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011202",
                dataKey: "J-003",
                title: "网带步进间隔",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "秒",
                rankOrder: 2,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011203",
                dataKey: "J-004",
                title: "网带步进速度",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "m/s",
                rankOrder: 3,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011204",
                dataKey: "J-005",
                title: "入口实时温度",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "温度",
                unit: "°C",
                rankOrder: 4,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011205",
                dataKey: "J-006",
                title: "出口实时速度",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "温度",
                unit: "°C",
                rankOrder: 5,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000011206",
                dataKey: "J-007",
                title: "网带偏差均值",
                extJson: null,
                extJsonSuper: null,
                value: "0",
                dataType: "",
                unit: "mm",
                rankOrder: 6,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
            ],
          },
          {
            groupInfo: {
              id: "10000122",
              tabId: "1000012",
              rankOrder: 1,
              groupTitle: "加热炉温度图",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000012201",
                dataKey: "J-008",
                title: "T1",
                extJson: null,
                extJsonSuper: null,
                value: "1000",
                dataType: "温度",
                unit: "",
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012202",
                dataKey: "J-012",
                title: "T2",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "温度",
                unit: "",
                rankOrder: 2,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012203",
                dataKey: "J-016",
                title: "T3",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 3,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012204",
                dataKey: "J-020",
                title: "T4",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 4,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012205",
                dataKey: "J-024",
                title: "T5",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 5,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012206",
                dataKey: "J-029",
                title: "T6",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "",
                unit: "",
                rankOrder: 6,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012207",
                dataKey: "J-032",
                title: "T7",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 7,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012208",
                dataKey: "J-036",
                title: "T8",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 8,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012209",
                dataKey: "J-040",
                title: "T9",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 9,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012210",
                dataKey: "J-044",
                title: "T10",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 10,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012211",
                dataKey: "J-048",
                title: "T11",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 11,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012212",
                dataKey: "J-052",
                title: "T12",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 12,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012213",
                dataKey: "J-056",
                title: "T13",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 13,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012214",
                dataKey: "J-060",
                title: "T14",
                extJson: null,
                extJsonSuper: null,
                value: "1200",
                dataType: "温度",
                unit: "",
                rankOrder: 14,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012215",
                dataKey: "J-064",
                title: "T15",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "温度",
                unit: "",
                rankOrder: 15,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012216",
                dataKey: "J-068",
                title: "T16",
                extJson: null,
                extJsonSuper: null,
                value: "1000",
                dataType: "温度",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
            ],
          },
          {
            groupInfo: {
              id: "10000123",
              tabId: "1000012",
              rankOrder: 1,
              groupTitle: "加热炉实时温度",
              showType: 0,
              showTypeSub: 0,
              extJson: null,
            },
            dataItemValues: [
              {
                metaDataItemId: "1000012317",
                dataKey: "J-079",
                title: "T1",
                extJson: null,
                extJsonSuper: null,
                value: "800",
                dataType: "温度",
                unit: "",
                rankOrder: 1,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012318",
                dataKey: "J-080",
                title: "T2",
                extJson: null,
                extJsonSuper: null,
                value: "900",
                dataType: "温度",
                unit: "",
                rankOrder: 2,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012319",
                dataKey: "J-081",
                title: "T3",
                extJson: null,
                extJsonSuper: null,
                value: "970",
                dataType: "温度",
                unit: "",
                rankOrder: 3,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012320",
                dataKey: "J-082",
                title: "T4",
                extJson: null,
                extJsonSuper: null,
                value: "990",
                dataType: "温度",
                unit: "",
                rankOrder: 4,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012321",
                dataKey: "J-083",
                title: "T5",
                extJson: null,
                extJsonSuper: null,
                value: "1050",
                dataType: "温度",
                unit: "",
                rankOrder: 5,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012322",
                dataKey: "J-084",
                title: "T6",
                extJson: null,
                extJsonSuper: null,
                value: "1060",
                dataType: "温度",
                unit: "",
                rankOrder: 6,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012323",
                dataKey: "J-085",
                title: "T7",
                extJson: null,
                extJsonSuper: null,
                value: "1100",
                dataType: "温度",
                unit: "",
                rankOrder: 7,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012324",
                dataKey: "J-086",
                title: "T8",
                extJson: null,
                extJsonSuper: null,
                value: "1090",
                dataType: "温度",
                unit: "",
                rankOrder: 8,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012325",
                dataKey: "J-087",
                title: "T9",
                extJson: null,
                extJsonSuper: null,
                value: "1120",
                dataType: "温度",
                unit: "",
                rankOrder: 9,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012326",
                dataKey: "J-088",
                title: "T10",
                extJson: null,
                extJsonSuper: null,
                value: "1010",
                dataType: "温度",
                unit: "",
                rankOrder: 10,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012327",
                dataKey: "J-089",
                title: "T11",
                extJson: null,
                extJsonSuper: null,
                value: "1030",
                dataType: "温度",
                unit: "",
                rankOrder: 11,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012328",
                dataKey: "J-090",
                title: "T12",
                extJson: null,
                extJsonSuper: null,
                value: "999",
                dataType: "温度",
                unit: "",
                rankOrder: 12,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012329",
                dataKey: "J-091",
                title: "T13",
                extJson: null,
                extJsonSuper: null,
                value: "1150",
                dataType: "温度",
                unit: "",
                rankOrder: 13,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012330",
                dataKey: "J-092",
                title: "T14",
                extJson: null,
                extJsonSuper: null,
                value: "1190",
                dataType: "温度",
                unit: "",
                rankOrder: 14,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012331",
                dataKey: "J-093",
                title: "T15",
                extJson: null,
                extJsonSuper: null,
                value: "1120",
                dataType: "温度",
                unit: "",
                rankOrder: 15,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
              {
                metaDataItemId: "1000012332",
                dataKey: "J-094",
                title: "T16",
                extJson: null,
                extJsonSuper: null,
                value: "1120",
                dataType: "温度",
                unit: "",
                rankOrder: 16,
                minValue: 0,
                maxValue: 9999999,
                equipmentCode: "J-000",
              },
            ],
          },
        ],
        chartValue: null,
      },
    ],
  },
  msg: "操作成功",
};

// 降温
const jwData = {
  code: 0,
  data: {
    lines: [
      {
        id: "1",
        curveTitle: "测试曲线-位置1",
        itemCode: "100",
        itemBatchCode: "100001",
        curveJson:
          '[{"name":"T1","value":"220"},{"name":"T2","value":"250"},{"name":"T3","value":"280"},{"name":"T4","value":"320"},{"name":"T5","value":"520"}]',
        pointDtoList: [
          {
            name: "T1",
            value: "240",
          },
          {
            name: "T2",
            value: "260",
          },
          {
            name: "T3",
            value: "300",
          },
          {
            name: "T4",
            value: "340",
          },
          {
            name: "T5",
            value: "380",
          },
          {
            name: "T6",
            value: "420",
          },
          {
            name: "T7",
            value: "460",
          },
          {
            name: "T8",
            value: "500",
          },
          {
            name: "T9",
            value: "540",
          },
          {
            name: "T10",
            value: "600",
          },
          {
            name: "T11",
            value: "640",
          },
          {
            name: "T12",
            value: "680",
          },
          {
            name: "T13",
            value: "720",
          },
          {
            name: "T14",
            value: "760",
          },
          {
            name: "T15",
            value: "800",
          },
          {
            name: "出炉2秒",
            value: "860",
          },
          {
            name: "6秒",
            value: "900",
          },
          {
            name: "10秒",
            value: "850",
          },
          {
            name: "淬火2秒",
            value: "800",
          },
          {
            name: "淬火4秒",
            value: "760",
          },
        ],
      },
      {
        id: "2",
        curveTitle: "测试曲线-位置2",
        itemCode: "100",
        itemBatchCode: "100001",
        curveJson:
          '[{"name":"T1","value":"220"},{"name":"T2","value":"350"},{"name":"T3","value":"480"},{"name":"T4","value":"520"},{"name":"T5","value":"620"}]\r\n',
        pointDtoList: [
          {
            name: "T1",
            value: "200",
          },
          {
            name: "T2",
            value: "220",
          },
          {
            name: "T3",
            value: "260",
          },
          {
            name: "T4",
            value: "300",
          },
          {
            name: "T5",
            value: "340",
          },
          {
            name: "T6",
            value: "380",
          },
          {
            name: "T7",
            value: "420",
          },
          {
            name: "T8",
            value: "460",
          },
          {
            name: "T9",
            value: "500",
          },
          {
            name: "T10",
            value: "560",
          },
          {
            name: "T11",
            value: "600",
          },
          {
            name: "T12",
            value: "640",
          },
          {
            name: "T13",
            value: "680",
          },
          {
            name: "T14",
            value: "720",
          },
          {
            name: "T15",
            value: "780",
          },
          {
            name: "出炉2秒",
            value: "820",
          },
          {
            name: "6秒",
            value: "880",
          },
          {
            name: "10秒",
            value: "800",
          },
          {
            name: "淬火2秒",
            value: "760",
          },
          {
            name: "淬火4秒",
            value: "700",
          },
        ],
      },
    ],
  },
  msg: "操作成功",
};

const itemData = {
  code: 0,
  data: {
    timeLineCodes: [
      {
        curveItemBatchCode: "100001",
        curveDate: "2023-01-16 16:52:12",
      },
    ],
  },
  msg: "操作成功",
};

export { overallData, gyData, jwData, itemData };
